const pages = [
  {
    path: '/admin/dashboard',
    name: 'admin-dashboard',
    component: () => import('@/views/admin/Warrant/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/warrant-list',
    name: 'admin-warrant-list',
    component: () => import('@/views/admin/Warrant/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/warrant-list-by-post',
    name: 'admin-warrant-list-by-post',
    component: () => import('@/views/admin/Warrant/IndexByPost.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/warrant-list-by-invoice',
    name: 'admin-warrant-list-by-invoice',
    component: () => import('@/views/admin/Warrant/IndexByInvoice.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/warrant-create/:id?',
    name: 'admin-warrant-create',
    component: () => import('@/views/admin/Warrant/Create.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/warrant-edit/:id',
    name: 'admin-warrant-edit',
    component: () => import('@/views/admin/Warrant/Edit.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/warrant-view/:id',
    name: 'admin-warrant-view',
    component: () => import('@/views/admin/Warrant/Detail.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/warrant-quote/:id',
    name: 'admin-warrant-quote',
    component: () => import('@/views/admin/Warrant/Quote.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/warrant-barcode/:id',
    name: 'admin-warrant-barcode',
    component: () => import('@/views/admin/Warrant/WarrantBarcode.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/admin/warrant-checkcode/:id',
    name: 'admin-warrant-checkcode',
    component: () => import('@/views/admin/Warrant/WarrantCheckcode.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/admin/company-list',
    name: 'admin-company-list',
    component: () => import('@/views/admin/Company/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/company-edit/:id',
    name: 'admin-company-edit',
    component: () => import('@/views/admin/Company/Edit.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/company-create',
    name: 'admin-company-create',
    component: () => import('@/views/admin/Company/Create.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/payment-list/:id',
    name: 'admin-payment-list',
    component: () => import('@/views/admin/Company/Payment/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/payment-edit/:id',
    name: 'admin-payment-edit',
    component: () => import('@/views/admin/Company/Payment/Edit.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/payment-create/:id',
    name: 'admin-payment-create',
    component: () => import('@/views/admin/Company/Payment/Create.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/user-list',
    name: 'admin-user-list',
    component: () => import('@/views/admin/User/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/user-create',
    name: 'admin-user-create',
    component: () => import('@/views/admin/User/Create.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/user-edit/:id',
    name: 'admin-user-edit',
    component: () => import('@/views/admin/User/Edit.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/equipment-list',
    name: 'admin-equipment-list',
    component: () => import('@/views/admin/Equipment/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/equipment-create',
    name: 'admin-equipment-create',
    component: () => import('@/views/admin/Equipment/Create.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/equipment-edit/:id',
    name: 'admin-equipment-edit',
    component: () => import('@/views/admin/Equipment/Edit.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/stock-list',
    name: 'admin-stock-list',
    component: () => import('@/views/admin/Stock/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/stock-create',
    name: 'admin-stock-create',
    component: () => import('@/views/admin/Stock/Create.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/stock-edit/:id',
    name: 'admin-stock-edit',
    component: () => import('@/views/admin/Stock/Edit.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/stock-management/:id',
    name: 'admin-stock-management',
    component: () => import('@/views/admin/Stock/Item/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/stock-management/:id/item/:item_id?',
    name: 'admin-stock-management-add',
    component: () => import('@/views/admin/Stock/Item/Add.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/stock-category-list',
    name: 'admin-stock-category-list',
    component: () => import('@/views/admin/Stock/Category/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/stock-category-create',
    name: 'admin-stock-category-create',
    component: () => import('@/views/admin/Stock/Category/Create.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/stock-category-edit/:id',
    name: 'admin-stock-category-edit',
    component: () => import('@/views/admin/Stock/Category/Edit.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/item-list',
    name: 'admin-item-list',
    component: () => import('@/views/admin/Item/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/item-create',
    name: 'admin-item-create',
    component: () => import('@/views/admin/Item/Create.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/item-edit/:id',
    name: 'admin-item-edit',
    component: () => import('@/views/admin/Item/Edit.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/daily-income',
    name: 'admin-daily-income',
    component: () => import('@/views/admin/Report/DailySale.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/employees-report',
    name: 'admin-employees-report',
    component: () => import('@/views/admin/Report/EmployeeReport.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/warrants-report',
    name: 'admin-warrants-report',
    component: () => import('@/views/admin/Report/WarrantReport.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/sales-analytic',
    name: 'admin-sales-analytic',
    component: () => import('@/views/admin/Report/SalesAnalytics.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/todo-list',
    name: 'admin-todo-list',
    component: () => import('@/views/admin/Todo/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/todo-create',
    name: 'admin-todo-create',
    component: () => import('@/views/admin/Todo/Create.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/todo-edit/:id',
    name: 'admin-todo-edit',
    component: () => import('@/views/admin/Todo/Edit.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/diary-list',
    name: 'admin-diary-list',
    component: () => import('@/views/admin/Diary/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/diary-create',
    name: 'admin-diary-create',
    component: () => import('@/views/admin/Diary/Create.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/diary-edit/:id',
    name: 'admin-diary-edit',
    component: () => import('@/views/admin/Diary/Edit.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/admin/planner',
    name: 'admin-planner-list',
    component: () => import('@/views/admin/Planner/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/warrant-status-check',
    name: 'warrant-status-check',
    component: () => import('@/views/customer/WarrantStatusCheck.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
]

export default pages
