import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import axiosIns from '@axios'
import app from './app'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userTypes: [
      { key: 'super_admin', name: 'Super admin' },
      { key: 'admin', name: 'Admin' },
      { key: 'user', name: 'User' },
    ],
    baseStatuses: [
      { key: 'active', name: 'active' },
      { key: 'disabled', name: 'disabled' },
    ],
    languages: [
      { key: 'de', name: 'German' },
      { key: 'fr', name: 'French' },
      { key: 'it', name: 'Italian' },
      { key: 'en', name: 'English' },
      { key: 'srLatn', name: 'Serbian' },
    ],
    warrantStatuses: [
      { key: 'draft', name: 'draft' },
      { key: 'active', name: 'active' },
      { key: 'complete', name: 'complete' },
      { key: 'trashed', name: 'trashed' },
    ],
    warrantCategories: [
      { key: 'not_selected', name: 'Not specified' },
      { key: 'cnc', name: 'CNC' },
      { key: 'laser', name: 'Laser' },
      { key: 'print', name: 'Print' },
      { key: 'silicon', name: 'Silicon' },
      { key: 'pictures', name: 'Pictures' },
      { key: 'models', name: 'Models' },
    ],
    warrantActions: [
      { key: 'preview', name: 'Preview' },
      { key: 'duplicate', name: 'Duplicate' },
      { key: 'edit', name: 'Edit' },
      { key: 'delete', name: 'Delete' },
    ],
    paymentMethods: [
      { key: 'cash', name: 'Cash' },
      { key: 'invoice', name: 'Invoice' },
    ],
    todoTypes: [
      { key: 'todo', name: 'Todo list' },
      { key: 'delivery', name: 'Delivery reminder' },
    ],
    diaryTypes: [
      { key: 'activity', name: 'Activity' },
      { key: 'suggestion', name: 'Suggestion' },
      { key: 'problem', name: 'Problem' },
    ],
    users: [],
    equipments: [],
    items: [],
    stocks: [],
    stockItemCategories: [],
    selectedStockItemCategory: null,
    changedWarrant: null,
  },
  mutations: {
    UPDATE_CHANGED_WARRANT(state, id) {
      state.changedWarrant = id
    },
    SET_USERS(state, users) {
      state.users = users
    },
    SET_EQUIPMENTS(state, equipments) {
      state.equipments = equipments
    },
    SET_ITEMS(state, items) {
      state.items = items
    },
    SET_STOCKS(state, stocks) {
      state.stocks = stocks
    },
    SET_STOCK_ITEM_CATEGORIES(state, stockItemCategories) {
      state.stockItemCategories = stockItemCategories
    },
    SET_STOCK_ITEM_CATEGORY(state, category) {
      state.selectedStockItemCategory = category
    },
  },
  actions: {
    async fetchUsers({ commit }) {
      try {
        const data = await axiosIns.get('/admin/users?keyValuePairs=1')
        await commit('SET_USERS', data.data.users)
      } catch (error) {
        // console.log(error)
      }
    },
    async fetchEquipments({ commit }) {
      try {
        const data = await axiosIns.get('/admin/equipments?keyValuePairs=1')
        await commit('SET_EQUIPMENTS', data.data.equipments)
      } catch (error) {
        // console.log(error)
      }
    },
    async fetchItems({ commit }) {
      try {
        const data = await axiosIns.get('/admin/items?keyValuePairs=1')
        await commit('SET_ITEMS', data.data.items)
      } catch (error) {
        // console.log(error)
      }
    },
    async fetchStocks({ commit }) {
      try {
        const data = await axiosIns.get('/admin/stocks?perPage=1000')
        await commit('SET_STOCKS', data.data.stocks.data)
      } catch (error) {
        // console.log(error)
      }
    },
    async fetchStockItemCategories({ commit }) {
      try {
        const data = await axiosIns.get('/admin/stock-categories?page=1&perPage=1000')
        await commit('SET_STOCK_ITEM_CATEGORIES', data.data.stockItemCategories.data)
      } catch (error) {
        // console.log(error)
      }
    },
  },
  modules: {
    appConfig: appConfigStoreModule,
    app,
  },

  getters: {
    statusesWithTrashed: state => state.baseStatuses,
    getUsers: state => state.users,
    getEquipments: state => state.equipments,
    getItems: state => state.items,
    getStocks: state => state.stocks,
    getStockItemCategories: state => state.stockItemCategories,
    getSelectedStockItemCategory: state => state.selectedStockItemCategory,
    getChangedWarrant: state => state.changedWarrant,
  },
})
