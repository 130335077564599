<template>
  <div class="vertical-nav-header d-flex align-center justify-start ps-6 pe-5 pt-5 pb-2 mb-4">
    <router-link
      to="/admin/dashboard"
      class="d-flex align-center text-decoration-none"
    >
      <v-img
        :src="appLogo"
        max-height="42px"
        alt="logo"
        contain
        eager
        class="app-logo me-3"
      ></v-img>
    </router-link>
    <div
      class="text-uppercase pl-4 cursor-pointer"
      @click="showModal()"
    >
      {{ $t('Delivery') }}
    </div>

    <v-dialog
      v-model="isDeliveryModalOpen"
      max-width="600px"
    >
      <v-card :key="isDeliveryModalOpen">
        <v-card-title class="border-bottom-2px-silver">
          <span
            class="headline"
          >{{ $t('Delivery') }}</span>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-text-field
              ref="inputRef"
              v-model="selectedItemRef"
              outlined
              dense
              :label="$t('Warrant')"
              @keyup.enter="getSelectedWarrantItem()"
            >
            </v-text-field>
            <v-list-item
              v-if="warrantNotFound"
              class="bordered-column py-2"
            >
              {{ $t('Warrant not found') }}
            </v-list-item>
            <v-list-item
              v-if="selectedItem"
              class="bordered-column py-2"
            >
              <v-row>
                <v-col
                  cols="12"
                  class="col-lg-4"
                >
                  <v-icon>{{ icons.mdiAccountOutline }}</v-icon>
                  {{ selectedItem.referent_name }}
                </v-col>
                <v-col
                  cols="12"
                  class="col-lg-3"
                >
                  <v-icon>{{ icons.mdiPhoneOutline }}</v-icon>
                  {{ selectedItem.phone }}
                </v-col>
                <v-col
                  cols="12"
                  class="col-lg-5"
                >
                  <v-icon>{{ icons.mdiEmailOutline }}</v-icon>
                  {{ selectedItem.email }}
                </v-col>
              </v-row>
            </v-list-item>
            <v-select
              v-if="selectedItem"
              v-model="selectedItem.payment_method"
              dense
              outlined
              :label="$t('Payment')"
              class="w-full mb-1"
              :items="$store.state.paymentMethods"
              item-value="key"
              :item-text="item => $t(item.name)"
              hide-details
            >
            </v-select>
            <v-btn
              v-if="selectedItem && selectedItem.payment_method === 'invoice'"
              key="invoicing-1"
              class="mt-2 col-6"
              :class="selectedItem.is_invoiced ? 'success' : 'secondary'"
              @click.prevent="() => {selectedItem.invoice = true; selectedItem.is_invoiced = !selectedItem.is_invoiced}"
            >
              {{ $t('Invoiced') }}
            </v-btn>
            <v-btn
              v-if="selectedItem && selectedItem.payment_method === 'invoice'"
              key="invoicing-2"
              class="mt-2 col-6"
              :class="!selectedItem.is_invoiced ? 'success' : 'secondary'"
              @click.prevent="() => {selectedItem.invoice = false; selectedItem.is_invoiced = !selectedItem.is_invoiced}"
            >
              {{ $t('Not invoiced') }}
            </v-btn>
            <v-btn
              v-if="selectedItem"
              key="invoicing-3"
              class="mt-2 col-6"
              :class="selectedItem.is_paid ? 'success' : 'secondary'"
              @click.prevent="() => {selectedItem.is_paid = !selectedItem.is_paid}"
            >
              {{ $t('Paid') }}
            </v-btn>
            <v-btn
              v-if="selectedItem"
              key="invoicing-4"
              class="mt-2 col-6"
              :class="!selectedItem.is_paid ? 'success' : 'secondary'"
              @click.prevent="() => {selectedItem.is_paid = !selectedItem.is_paid}"
            >
              {{ $t('Not paid') }}
            </v-btn>
            <v-list-item
              v-if="selectedItem"
              :to="{ name: 'admin-warrant-view', params: { id: selectedItem.id }}"
              target='_blank'
              style="padding: 0 !important;"
              key="invoicing-7"
              class="v-btn v-btn--is-elevated theme--dark v-size--default col-6 mt-2 info"
              @click="generateInvoice()"
            >
              {{ $t('Generate invoice') }}
            </v-list-item>
            <v-list-item
              v-if="selectedItem"
              :to="{ name: 'admin-warrant-quote', params: { id: selectedItem.id }}"
              target='_blank'
              style="padding: 0 !important;"
              key="invoicing-8"
              class="v-btn v-btn--is-elevated theme--dark v-size--default col-6 mt-2 info"
            >
              {{ $t('Generate quote') }}
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            v-if="selectedItem"
            color="success"
            outlined
            @click="change(selectedItem, true)"
          >
            {{ $t('deliver') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiRadioboxBlank,
  mdiRecordCircleOutline,
  mdiClose,
  mdiAccountOutline,
  mdiPhoneOutline,
  mdiEmailOutline,
} from '@mdi/js'
import useAppConfig from '@core/@app-config/useAppConfig'
import themeConfig from '@themeConfig'
import { inject } from '@vue/composition-api'
import axiosIns from '@axios'

export default {
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()
    const isMouseHovered = inject('isMouseHovered')

    return {
      warrantNotFound: false,
      isDeliveryModalOpen: false,
      menuIsVerticalNavMini,
      isMouseHovered,
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      selectedItem: null,
      selectedItemRef: null,

      // Icons
      icons: {
        mdiClose,
        mdiRadioboxBlank,
        mdiRecordCircleOutline,
        mdiAccountOutline,
        mdiPhoneOutline,
        mdiEmailOutline,
      },
    }
  },
  methods: {
    generateInvoice() {
      if (this.selectedItem) {
        axiosIns({
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          data: JSON.stringify({ status: 'complete' }),
          url: `/admin/warrants/${this.selectedItem.id}`,
        }).then()
      }
    },
    showModal() {
      this.isDeliveryModalOpen = true
      this.selectedItem = null
      this.selectedItemRef = null
      setTimeout(() => { this.$refs.inputRef.focus() }, 300)
    },
    change(input, isDelivery = false) {
      axiosIns({
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify({
          invoice: input.payment_method === 'invoice',
          is_invoiced: input.is_invoiced,
          is_paid: input.is_paid,
          is_delivered: isDelivery || input.is_delivered,
          payment_method: input.payment_method,
        }),
        url: `/admin/warrants/${this.selectedItem.id}`,
      }).then(res => {
        const { warrant } = res.data
        this.selectedItem = warrant
        this.snackbar = true
        this.$store.commit('UPDATE_CHANGED_WARRANT', warrant.updated_at)
        if(isDelivery){
          this.isDeliveryModalOpen = false
        }
      })
    },
    getSelectedWarrantItem() {
      axiosIns({
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        url: `/admin/warrants/${this.selectedItemRef}/reference`,
      })
        .then(res => {
          const { warrant } = res.data
          this.selectedItem = warrant

          this.warrantNotFound = !warrant
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}
</style>
