export default {

  'Not categorized': 'Nekategorizovano',
  Category: 'Kategorija',
  myAccount: 'Moj nalog',
  noResults: 'Nema rezultata',
  details: 'Detalji',
  edit: 'Uredi',
  print: 'Štampaj',
  'print-code': 'Štampaj kod',
  archive: 'Arhiviraj',
  'hand-over': 'Isporuka',
  deliver: 'Isporuči',
  'print label': 'Nalepnica',
  delete: 'Izbriši',
  Settings: 'Podešavanja',
  'Items per page': 'Broj zapisa po strani',
  Password: 'Šifra',
  Title: 'Naslov',
  Quantity: 'Količina',
  'Warrant number': 'Nalog br. ',
  'Warrant code': 'Kod za proveru',
  'Warrant not found': 'Nalog nije pronadjen, pokusajte ponovo',
  'Client data': 'Podaci i klijentu',
  Company: 'Firma',
  'Full name': 'Ime i prezime',
  Phone: 'Telefon',
  Address: 'Adresa',
  Email: 'Email',
  'ID number': 'PDV broj',
  'Job info': 'Podaci o poslu',
  'Job type': 'Vrsta posla',
  File: 'Fajl',
  Payment: 'Plaćanje',
  Cash: 'Gotovina',
  Invoice: 'Faktura',
  cash: 'Gotovina',
  invoice: 'Otpremnica',
  quote: 'Predračun',
  'Payment method': 'Način plaćanja',
  'From date': 'Od',
  'To date': 'Do',
  Deadline: 'Rok',
  Discount: 'Rabat',
  'By post': 'Slanje poštom',
  'For invoicing': 'Za fakturisanje',
  Invoiced: 'Fakturisano',
  'Not invoiced': 'Nije fakturisano',
  'Generate invoice': 'Izdati otpremnicu',
  'Generate quote': 'Izdati predračun',
  'Add to account': 'Dodaj na račun',
  'Client provided material': 'Klijentov materijal',
  'Location data': 'Podaci o lokaciji',
  'Location of creation': 'Lokacija prijema',
  'Takeover location': 'Lokacija preuzimanja',
  'Created by': 'Kreirao',
  'Created on': 'Kreirano',
  Informed: 'Javljeno',
  Message: 'Poruka',
  Paid: 'Plaćeno',
  'Not paid': 'Nije plaćeno',
  Delivered: 'Isporučeno',
  Delivery: 'Isporuka',
  Save: 'Snimi',
  Note: 'Napomena',
  Code: 'Šifra',
  Product: 'Proizvod',
  Price: 'Cena',
  'Final Price': 'Krajnja cena',
  Employee: 'Radnik',
  Machine: 'Mašina',
  'An error occurred': 'Došlo je do greške, molimo pokušajte ponovo',
  Referent: 'Kontakt osoba',
  Material: 'Materijal',
  '#': 'RB',
  'Only clients': 'Samo mušterije',
  'Payment history': 'Istorija naplate',
  'Payment list': 'Lista naplate',
  Value: 'Vrednost',
  Comment: 'Komentar',
  'Create payment': 'Dodaj uplatu',
  'Edit payment': 'Uredi uplatu',
  'Planner list': 'Pregled planera',
  'Completed warrants': 'Ukupno naloga',

  'Company list': 'Lista firmi',
  Name: 'Naziv',
  'Company type': 'Tip kompanije',
  'Add company': 'Dodaj firmu',
  'Zip code': 'Poštanski broj',
  City: 'Grad',
  Country: 'Država',
  'Company users': 'Korisnici',
  'First name': 'Ime',
  'Last name': 'Prezime',
  Mobile: 'Mobilni',
  'Add/Edit user': 'Dodaj/uredi korisnika',
  'Password confirmation': 'Potvrda šifre',
  '2FA': '2FA metod',

  'Todo list': 'Todo lista',
  'Delivery reminder': 'Podsetnik za dostavu',
  'Create todo': 'Novi todo',
  delivery: 'Podestnik za dostavu',
  todo: 'Todo lista',
  'User filter': 'Filter po korisniku',

  'User list': 'Lista korisnika',
  'Create user': 'Dodaj korisnika',
  'Edit user': 'Ažuriraj  korisnika',

  'Equipment list': 'Lista mašina i opreme',
  Process: 'Proces',
  'Create equipment': 'Dodaj mašinu',
  'Edit equipment': 'Uredi mašinu',
  'Stock list': 'Lista magacina',
  'Create stock': 'Dodaj magacin',
  'Edit stock': 'Uredi magacin',
  Items: 'Proizvodi',
  Item: 'Proizvod',
  'Item list': 'Lista proizvoda',
  'Create item': 'Dodavanje proizvoda',
  'Edit item': 'Izmena proizvoda',
  'Add material to stock': 'Dodaj materijal u magacin',
  'Stock management': 'Upravljanje zalihom',
  'Material categories': 'Kategorije materijala',
  'Edit material category': 'Uredi kategoriju materijala',
  'Create material category': 'Dodaj kategoriju materijala',

  Done: 'Završeno',
  Active: 'Aktivno',

  // messages
  'Data successfully saved': 'Podaci uspešno sačuvani',
  Close: 'Zatvori',
  Create: 'Dodaj',
  'Type to search': 'Kucajte za pretragu',
  Statuses: 'Statusi',
  'Process filter': 'Filtriranje po procesima',
  'Deadline filter': 'Filter po roku izrade (od-do)',
  'Location filter': 'Filter po lokaciji',
  Type: 'Tip',
  'Type filter': 'Filter po tipu',
  Location: 'Lokacija',

  // menus and theme translations
  Dashboards: 'Početna',
  Analytics: 'Analitika',
  eCommerce: 'Online prodaja',
  Dashboard: 'Početna',
  Warrants: 'Nalozi',
  'Create warrant': 'Dodaj nalog',
  'Warrant list': 'Lista naloga',
  Warrant: 'Nalog',
  CRM: 'CRM',
  Companies: 'Firme',
  'Company List': 'Lista firmi',
  'Add Company': 'Dodaj firmu',
  'Add User': 'Dodaj korisnika',
  Reports: 'Izveštaji',
  Subscriptions: 'Pretplate',
  'User Management': 'Korisnici',
  'Equipment Management': 'Mašine i oprema',
  Stock: 'Magacin',
  Journal: 'Dnevnik',
  Planner: 'Planer',
  'Equipment List': 'Lista mašina',
  'Add Equipment': 'Dodaj mašinu',
  'Daily income': 'Dnevni promet',
  'Employees report': 'Izveštaj po radnicima',
  'Warrants report': 'Izveštaj po nalozima',
  'Sales analytic': 'Analitika prodaje',
  References: 'Reference',
  'App examples': 'Primeri aplikacija',
  'User interface': 'Korisnički interfejs',
  'Forms & tables': 'Forme & tabele',
  'Charts & maps': 'Grafikoni i mape',
  'Other examples': 'Drugi primeri',
  Calendar: 'Kalendar',
  Chat: 'Chat',
  List: 'Lista',
  Preview: 'Pregled',
  Edit: 'Izmena',
  Add: 'Dodaj',
  User: 'Korisnik',
  'User List': 'Lista korisnika',
  'User View': 'Pregled korisnika',
  'APPS AND PAGES': 'Aplikacije i stranice',
  Pages: 'stranice',
  'Page Not Found': 'Stranica ne postoji',
  Authentication: 'Autentikacija',
  'Login V1': 'Login V1',
  'Login V2': 'Login V2',
  'Register V1': 'Registracija V1',
  'Register V2': 'Registracija V2',
  'Forgot Password V1': 'Zaboravljena šifra V1',
  'Forgot Password V2': 'Zaboravljena šifra V2',
  'Reset Password V1': 'Reset šifre V1',
  'Reset Password V2': 'Reset šifre V2',
  Miscellaneous: 'Ostalo',
  'Under Maintenance': 'Ažuriranje u toku',
  Error: 'Greška',
  'Coming Soon': 'Uskoro',
  'Not Authorized': 'Niste autorizovani',
  'Knowledge Base': 'Baza znanja',
  'Account Settings': 'Podešavanje naloga',
  Pricing: 'Cene',
  FAQ: 'Česta pitanja',
  'USER INTERFACE': 'Korisnički interfejs',
  Typography: 'Tipografija',
  Icons: 'Ikone',
  Gamification: 'Gamification',
  Cards: 'Kartice',
  Basic: 'Osnovno',
  Statistics: 'Statistika',
  Advance: 'Napredno',
  Actions: 'Akcije',
  Chart: 'Grafikon',
  Components: 'Komponente',
  Alert: 'Upozorenje',
  Avatar: 'Avatar',
  Badge: 'Oznaka',
  Button: 'Dugme',
  Menu: 'Meni',
  'Expansion Panel': 'Proširivi panel',
  Dialog: 'Modal',
  Pagination: 'Stranice',
  Tabs: 'Tabovi',
  Tooltip: 'Nagoveštavanje',
  Chip: 'Labele',
  Snackbar: 'Snackbar',
  Stepper: 'Steper',
  Timeline: 'Vremenska linija',
  Treeview: 'Strukturalni pregled',
  'FORMS & TABLES': 'Forme i tabele',
  'Forms Elements': 'Elementi formi',
  Autocomplete: 'Autocomplete',
  Checkbox: 'Čekboks',
  Combobox: 'Komboboks',
  'File Input': 'Fajl',
  Radio: 'Radio',
  'Range Slider': 'Range Slider',
  Select: 'Select',
  Slider: 'Slider',
  Switch: 'Switch',
  Textarea: 'Textarea',
  Textfield: 'Textfield',
  'Date Picker': 'Date Picker',
  'Month Picker': 'Month Picker',
  'Time Picker': 'Time Picker',
  Rating: 'Rating',
  'Form Layout': 'Form Layout',
  'Form validation': 'Form validation',
  Tables: 'Tables',
  'Simple Table': 'Simple Table',
  Datatable: 'Datatable',
  'CHARTS AND MAPS': 'CHARTS AND MAPS',
  Charts: 'Charts',
  'Apex Chart': 'Apex Chart',
  Chartjs: 'Chartjs',
  'Leaflet Maps': 'Leaflet Maps',
  Others: 'Others',
  'Menu Levels': 'Menu Levels',
  'Menu Level 2.1': 'Menu Level 2.1',
  'Menu Level 2.2': 'Menu Level 2.2',
  'Menu Level 3.1': 'Menu Level 3.1',
  'Menu Level 3.2': 'Menu Level 3.2',
  'Disabled Menu': 'Disabled Menu',
  'Raise Support': 'Raise Support',
  Documentation: 'Documentation',
  'Charts & Maps': 'Charts & Maps',
  Apps: 'Apps',
  'User Interface': 'User Interface',
  'Forms & Tables': 'Forms & Tables',

  // user types select
  'Super admin': 'Super admin',
  'Cleangreen admin': 'Korisnik',
  Admin: 'Admin',

  // company types select
  Client: 'Mušterija',
  Supplier: 'Dostavljač',

  'Is paid?': 'Plaćeno?',
  'Is delivered?': 'Isporučeno?',
  'Is invoiced?': 'Fakturisano?',
  Sent: 'Poslato',
  'Not delivered': 'Nije poslato',
  'Return to delivery': 'Vrati u za slanje',
  'Return to invoicing': 'Vrati u za fakturisanje',
  Yes: 'Da',
  No: 'Ne',
  Shelf: 'Polica',

  // statuses select
  draft: 'Na čekanju',
  active: 'Aktivno',
  complete: 'Završeno',
  archived: 'Arhivirano',
  disabled: 'Onemogućeno',
  trashed: 'Izbrisano',
  stopped: 'Završeno',

  Finish: 'Završi',
  Activate: 'Aktiviraj',
  Copied: 'Kopirano',

  activity: 'Aktivnost',
  Activity: 'Aktivnost',
  Suggestion: 'Sugestija',
  Problem: 'Problem',
  'Diary list': 'Pregled dnevnika',
  'Create diary': 'Dodaj zapis',
  'Edit diary': 'Uredi zapis',

  // languages select
  English: 'Engleski',
  German: 'Nemački',
  French: 'Francuski',
  Italian: 'Italijanski',
  Serbian: 'Srpski',

  // warrant categories select
  'Not specified': 'Nije određeno',
  Not_selected: 'Nije određeno',
  Cnc: 'CNC',
  Laser: 'Laser',
  Print: 'Štampa',
  Silicon: 'Silikon',
  Models: 'Makete',
  Pictures: 'Slike',
  Ads: 'Reklame',
  'Send Invoice': 'Pošalji fakturu',

  // warrant actions select
  Duplicate: 'Dupliraj',
  Delete: 'Izbriši',

  // Invoice
  'Invoice for': 'Faktura za',
  'Billing to': 'Naplata od',
  Subtotal: 'Ukupno',
  'Thank you for your cooperation': 'Hvala na saradnji', // TODO:
  'Total without discount and taxes': 'Ukupno pre popusta i PDV-a',
  Tax: 'PDV',
  'It was a pleasure to work with you!': 'Bilo je zadovoljstvo raditi sa Vama! Hvala i tu smo za Vas!',

  // input field options select
  Subtitle: 'Subtitle',
  Description: 'Opis',
  'Short text': 'Short text',
  'Long text': 'Long text',
  Number: 'Number',
  'Radio button': 'Radio button',
  'File upload': 'File upload',
  Date: 'Datum',
  Unit: 'Jedinica',

  yes: 'Da',
  no: 'Ne',
  of: 'od',
  suggestion: 'Sugestija',
  problem: 'Problem',
  Restore: 'Vrati',

  'Item already exists': 'Zapis već postoji',

  general: {
    typeToSearch: 'Kucajte za pretragu',
    statusFilter: 'Filter po statusu',
    categoryFilter: 'Kategorijie',
    locationFilter: 'Lokacije',
    language: 'Jezik',
    or: 'ili',
    welcomeMessage: 'Dobrodošli!',
    forgotPassword: 'Zaboravljena šifra',
    save: 'Snimi',
    close: 'Zatvori',
    ok: 'ok',
    cancel: 'Odustani',
    confirm: 'Potvrdi',
    anErrorOccurred: 'Došlo je do greške, pokušajte ponovo.',
    status: 'Status',
    name: 'Naziv',
    edit: 'Izmena',
    delete: 'Izbriši',
    restore: 'Vrati',
    details: 'Detalji',
    user: 'Korisnik',
    company: 'Firma',
    type: 'Tip',
  },
  login: {
    signInMessage: 'Molimo ulogujte se',
    email: 'Email',
    password: 'Šifra',
    rememberMe: 'Zapamti me',
    login: 'Login',
    username: 'Korisničko ime',
    newOnPlatform: 'Prvi put ste ovde?',
    createAccount: 'Kreiranje naloga',
    invalidCredentials: 'Neispravni podaci, pokusajte ponovo',
    emailVerified: 'Email uspešno verifikovan',
  },
  register: {
    fillUpMessage: 'Molimo popunite formu',
    userAgreement: 'Slažem se sa',
    privacyAndTerms: 'politikom privatnosti i uslovima korišćenja',
    alreadyHaveAccount: 'Već imate nalog?',
    signInInstead: 'Ulogujte se',
    successfullyRegistered: 'Vaš nalog je uspešno kreiran. '
      + 'Molimo verifikujte Vaš email.',
    invalidRegistrationData: 'Unešeni podaci nisu validni. Ako ste koristili ovaj email ranije, molimo ulogujte se.',
    resend: 'Ponovo pošalji',
    resendEmailMessage: 'Niste dobili email?',
    companyType: 'Odaberite tip profila',
  },
  forgotPassword: {
    enterEmail: 'Unesite Vaš email, poslaćemo Vam instrukcije za reset šifre',
    sendLink: 'Pošalji link za reset',
    backToLogin: 'Nazad na login',
    emailSent: 'Poslali smo Vam email sa instrukcijama za reset šifre',
    resetPassword: 'Reset šifre',
    passwordSuccessfullyReset: 'Vaša šifra je promenjena, možete se ulogovati koristeći istu',
  },

  languages: {
    german: 'Nemački',
    french: 'Francuski',
    italian: 'Italijanski',
    english: 'Engleski',
    serbian: 'Srpski',
  },
  footer: {
    copyright: '©',
    impressum: 'Impresum',
  },

  // backend error keys
  invalid_credentials: 'Neispravni podaci',
  company_account_not_enabled: 'Nalog kompanije je blokiran, molimo kontaktirajte podršku',
  user_account_not_enabled: 'Vaš nalog je onemogućen, molimo kontaktirajte podršku',
  email_taken: 'Unesena email adresa je zauzeta, molimo koristite drugu',
  password_not_matched: 'Šifra i potvrda šifre se ne podudaraju, pokušajte ponovo',
  not_found: 'Traženi podaci nisu dostupni na ovom serveru',
  weak_password: 'Šifra mora da sadrži najmanje jedno veliko i malo slovo, specijalni znak i cifru i može imati najmanje 8 znakova',
  general_error: 'Došlo je do greške. Molimo pokušajte ponovo ili kontaktirajte podršku',
  general_success: 'Akcija je uspešno završena',

  // vuetify
  noDataText: 'Nema podataka',

  // validation
  'This field is required': 'Ovo je obavezno polje',
  'The Email field must be a valid email': 'Email polje mora biti validan email',
  'Field must contain at least one uppercase, lowercase, special character and digit with min 8 chars': 'Ovo polje mora sadržati bar jedno veliko slovo, jedno malo slovo, specijalni znak, broj i mora imati bar 8 karaktera',
  'The Confirm Password field confirmation does not match': 'Polje šifra i potvrda šifre se ne podudaraju',
}
